<template>
    <div class="main">
        <div class="title">发布需求信息-资金</div>
        <el-form
            :model="formData"
            :rules="rules"
            ref="form"
            label-width="135px"
            label-position="right"
            size="small"
            style="max-width: 335px"
        >
            <el-form-item prop="amount" label="拟融资额">
                <el-input placeholder="请输入拟融资额" v-model="formData.amount"></el-input>
            </el-form-item>
            <el-form-item prop="loanTerm" label="期望贷款期限">
                <el-select v-model="formData.loanTerm" clearable filterable placeholder="请选择" style="width: 100%">
                    <el-option
                        v-for="item in loanTermOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="purpose" label="融资目的">
                <template>
                    <el-select
                        v-model="formData.purpose"
                        multiple
                        clearable
                        filterable
                        placeholder="请选择融资目的，多个融资目的按回车分割"
                        style="width: 400px"
                    >
                        <el-option
                            v-for="item in purposeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </template>
            </el-form-item>
            <el-form-item prop="contact" label="联系人">
                <el-input v-model="formData.contact"></el-input>
            </el-form-item>
            <el-form-item prop="phone" label="电话">
                <el-input v-model="formData.phone"></el-input>
            </el-form-item>
            <el-form-item prop="address" label="所在地区">
                <el-input style="width: 400px" placeholder="请输入所在地区" v-model="formData.address"></el-input>
            </el-form-item>
            <el-form-item prop="email" label="邮箱">
                <el-input v-model="formData.email"></el-input>
            </el-form-item>
        </el-form>
        <div class="btn">
            <el-button @click="onSave" :loading="saving" type="primary">确定</el-button>
            <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button>
            <el-button type="info" @click="$router.go(-1)" :disabled="saving">返回</el-button>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { phonePattern } from '../../../utils/variables';
export default {
    name: 'FundingEdit',
    computed: {
        ...mapState(['userInfo'])
    },
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('funding/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                    this.formData = { ...res, email: this.userInfo.email };
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
        this.$http
            .post('/setting/byFlag', { flag: 5 })
            .then(res => {
                if (res.length > 0) {
                    res.forEach(item => {
                        this.purposeOptions.push({
                            label: item.name,
                            value: item.id
                        });
                    });
                }
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
        this.formData = {
            contact: this.userInfo.nickname,
            phone: this.userInfo.phone,
            email: this.userInfo.email
        };
    },
    data() {
        return {
            saving: false,
            formData: {},
            needChange: true,
            rules: {
                amount: [{ required: true, message: '请输入拟融资额', trigger: 'blur' }],
                loanTerm: [{ required: true, message: '请选择期望贷款期限', trigger: 'blur' }],
                purpose: [{ required: true, message: '请选择融资目的，可多选', trigger: 'blur' }],
                contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                phone: { required: true, pattern: phonePattern, message: '请输入联系电话', trigger: 'blur' },
                selfIntroduction: [{ required: true, message: '请输入', trigger: 'blur' }],
                email: [{ type: 'email', required: true, message: '请输入邮箱', trigger: 'blur' }],
                address: [{ required: true, message: '请输入所在地区', trigger: 'blur' }]
            },
            loanTermOptions: [
                { label: '3个月以下', value: 'UNDER_THREE_MONTHS' },
                { label: '3-6个月', value: 'THREE_SIX_MONTHS' },
                { label: '6个月-1年', value: 'SIX_MONTHS_ONE_YEAR' },
                { label: '1-2年', value: 'ONE_TWO_YEARS' }
            ],
            purposeOptions: []
        };
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                    setTimeout(() => {
                        this.$confirm('发布成功，正在等待平台审核', '提示', {
                            confirmButtonText: '继续发布',
                            cancelButtonText: '退出',
                            type: 'warning'
                        }).then(() => {
                            setTimeout(() => {
                                this.$router.push('/fundingEdit');
                                this.formData = '';
                                this.formData = {
                                    contact: this.userInfo.nickname,
                                    negotiateDirectly: false,
                                    phone: this.userInfo.phone,
                                    email: this.userInfo.email
                                };
                            }, 1000);
                        });
                        // .catch(() => {
                        //     this.show = false;
                        // });
                    }, 1000);
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData };
            this.saving = true;
            this.$http
                .post('/funding/save', data, { body: 'json' })
                .then(() => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/funding/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-button {
    width: 100px;
    border-radius: 4px;
    margin-left: 25px;
}
/deep/ .el-form {
    margin-top: 30px;
}
.main {
    background-color: #fff;
    margin: 17px 20px 0;
    padding-bottom: 60px;
    .btn {
        text-align: right;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        padding-bottom: 17px;
        line-height: 26px;
        border-bottom: 1px solid @bg;
    }
}
</style>
